(function () {
  'use strict';

  angular
    .module('learning.moduleSetEdit')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard.learning.moduleSetEdit', {
        url: '/module-set-edit/:id',
        templateUrl: 'dashboard/learning/module-set-edit/module-set-edit.tpl.html',
        controller: 'ModuleSetEditCtrl',
        controllerAs: 'moduleSetEdit',
        roles: ['superadmin', 'admin', 'Teacher']
      });
  }
}());
